<script lang="ts">
export default defineComponent({
    name: 'ArticleEeat',
    props: {
        blok: {
            type: Object as PropType<ArticleEeatBlokInterface>,
            required: true,
        },
        firstPublishedAt: {
            type: String,
            default: '',
        },
        publishedAt: {
            type: String,
            default: '',
        },
        seoData: {
            type: Object,
            required: true,
        },
        globalArticleCopy: {
            type: Object as PropType<GlobalArticleCopyInterface>,
            required: true,
        },
    },
    computed: {
        hasArticleDate() {
            return !!this.blok.legacyUpdatedDate || !!this.firstPublishedAt || !!this.publishedAt;
        },
    },
});
</script>

<template>
    <div
        v-editable="blok"
        class="mb-200 pt-100">
        <es-row>
            <es-col cols="12">
                <h1>
                    {{ blok.header }}
                </h1>
            </es-col>
        </es-row>
        <es-row>
            <es-col
                cols="12"
                lg="6">
                <p class="font-size-300 pt-0 mt-0 mb-50">
                    {{ blok.subheader }}
                </p>
                <!-- @vue-expect-error TS2339 -->
                <social-links
                    class="mb-150"
                    :nuxt-route-path="$route.path"
                    unique-id="eeat"
                    :share-text="blok.header"
                    :share-category="blok.category"
                    :seo-data="seoData"
                    :copy-tooltip-success="globalArticleCopy.linkCopySuccess" />
            </es-col>
            <es-col
                class="text-lg-right"
                cols="12"
                lg="6">
                <div class="mb-50">
                    <token-replace :string="globalArticleCopy.writtenByAuthors">
                        <template #authors>
                            <ul class="comma-separated-list list-unstyled d-inline-flex m-0 p-0">
                                <li
                                    v-for="author in blok.authors"
                                    :key="author.id"
                                    class="d-flex">
                                    <storyblok-link
                                        :link="{
                                            linktype: 'story',
                                            story: author,
                                        }">
                                        {{ author?.content?.authorInfo?.[0]?.name }}
                                    </storyblok-link>
                                </li>
                            </ul>
                        </template>
                    </token-replace>
                </div>
                <div
                    v-if="blok.editedBy"
                    class="mb-50">
                    <token-replace :string="globalArticleCopy.editedByAuthor">
                        <template #editor>
                            <storyblok-link
                                v-if="blok?.editedBy?.content"
                                :link="{
                                    linktype: 'story',
                                    story: blok.editedBy,
                                }">
                                {{ blok.editedBy.content?.authorInfo?.[0]?.name }}
                            </storyblok-link>
                        </template>
                    </token-replace>
                </div>
                <div class="d-flex d-lg-inline-flex justify-space-between mb-0">
                    <div
                        v-if="hasArticleDate"
                        class="mr-150">
                        <article-update-date
                            :first-published-at="firstPublishedAt"
                            :global-article-copy="globalArticleCopy"
                            :last-updated-date="blok.legacyUpdatedDate"
                            :published-at="publishedAt" />
                    </div>
                    <div>
                        <token-replace :string="globalArticleCopy.numMinuteRead">
                            <template #num>
                                {{ blok.readTimeMinutes }}
                            </template>
                        </token-replace>
                    </div>
                </div>
            </es-col>
        </es-row>
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';

.comma-separated-list {
    li:not(:last-child) {
        padding-right: variables.$spacer * 0.25;

        &::after {
            content: ',';
        }
    }
}
</style>
